$(document).ready(function () {
    $(".table-select").on("change", function (e) {
        var id = $(this).val();
        $(".table-responsive table.table-selected").hide();
        $('.table-responsive table[data-id="' + id + '"').fadeIn();
    });
    $(".end-quiz").on("click", function (e) {
        e.preventDefault();
        $(".quiz-part").hide();
        $(".result-part").fadeIn();
        $("html, body").animate(
            {
                scrollTop: $("#quiz-result").offset().top,
            },
            1000
        );
    });
    $(".restart-quiz").on("click", function (e) {
        e.preventDefault();
        $(".result-part").hide();
        $(".quiz-part").fadeIn();
        $(":input", "form")
            .not(":button, :submit, :reset, :hidden")
            .val("")
            .prop("checked", false)
            .prop("selected", false);
    });
    $("#burger-container").on("click", function (e) {
        e.stopPropagation();
        $(this).toggleClass("open");
        $(".menu-container li.has-submenu").removeClass("open");
        $(".menu-container").toggleClass("open");
    });
    $(".flexslider").flexslider({
        animation: "fade",
        touch: true,
        useCSS: false,
        animationLoop: true,
        controlNav: false,
        directionNav: false,
    });
    new WOW().init();
    $(".paroller").paroller();
    $(".date-field").datepicker({changeYear: true});
    $.datepicker.regional["pl"] = {
        closeText: "Zamknij",
        prevText: "&#x3C;Poprzedni",
        nextText: "Następny&#x3E;",
        currentText: "Dziś",
        monthNames: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
        ],
        monthNamesShort: [
            "Sty",
            "Lu",
            "Mar",
            "Kw",
            "Maj",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Pa",
            "Lis",
            "Gru",
        ],
        dayNames: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
        ],
        dayNamesShort: ["Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So"],
        dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
        weekHeader: "Tydz",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
    };

    $.datepicker.setDefaults(
        $.extend(
            {dateFormat: "dd.mm.yy", yearRange: "-130:+0"},
            $.datepicker.regional["pl"]
        )
    );
    $(document).on("click", function (e) {
        $("#burger-container").removeClass("open");
        $(".menu-container").removeClass("open");
        $(".menu-container li.has-submenu").removeClass("open");
    });
    $(document).on("click", 'a[href^="#"]', function (event) {
        event.preventDefault();
        if ($(this).attr("href").length > 1) {
            $("html, body").animate(
                {
                    scrollTop: $($.attr(this, "href")).offset().top,
                },
                500
            );
        }
    });
    $(".site-navigation").click(function (e) {
        e.stopPropagation();
    });
    $(
        ".menu-container>ul>li.has-submenu>span, .menu-container>ul>li.has-submenu>a"
    ).click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var parent = $(this).parent();
        $(".menu-container li.has-submenu").not(parent).removeClass("open");
        $(this).parent().toggleClass("open");
    });
    $(".menu-container").click(function (e) {
        e.stopPropagation();
    });
    $(".banner-scroll").click(function (e) {
        e.preventDefault();
        var target = $(e.target).closest("section").nextAll("section:first");
        if (!target.length) return;
        $("html,body").animate({scrollTop: $(target).offset().top - 80}, "slow");
    });
    $("#profile-container").click(function (e) {
        $("#imageUpload").click();
    });

    function fasterPreview(uploader) {
        if (uploader.files && uploader.files[0]) {
            $("#profileImage").attr(
                "src",
                window.URL.createObjectURL(uploader.files[0])
            );
        }
    }

    $("#imageUpload").change(function () {
        fasterPreview(this);
    });
    $(".content-box").typographer_orphan({});

    const testCountdown = document.getElementById("testCountdown")
    // var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

    if (testCountdown && endDate && startDate) {
        const x = setInterval(function () {

            const now = new Date().getTime();
            const distance = (startDate < now ? endDate : startDate) - now;
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            const sufix = startDate < now ? "do zakończenia" : "do rozpoczęcia"

            testCountdown.innerHTML = days + "d " + hours + "h "
                + minutes + "m " + seconds + "s " +
                "<br><small>" + sufix + "</small>";

            if (distance < 0) {
                clearInterval(x);
                testCountdown.innerHTML = "";
            }
        }, 1000);
    }

    const refresh = document.getElementById("refresh")
    if (refresh) {
        refresh.addEventListener("click", () => {
            location.reload()
        })
    }
    const testDurationTimer = document.getElementById("testDurationTimer")
    if (testDurationTimer) {
        const start = new Date().getTime();
        const s = setInterval(function () {
            // console.log("lol")
            const d = duration * 60000
            const now = new Date().getTime();
            const tillEnd = endDate - start

            const timeLeft = ( tillEnd > d ? start + d : endDate) - now
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
            testDurationTimer.innerHTML = hours + "h "
                + minutes + "m " + seconds + "s " +
                "<br><small> do końca egzaminu </small>";

            if (timeLeft <= 0) {
                clearInterval(s)
            }
        }, 1000);

    }
});
$(".office__show").click(function () {
    if ($(this).hasClass("show")) {
        $(".office__show").removeClass("show");
        $(".office__contact").removeClass("show");

    } else {
        $(".office__contact").removeClass("show");
        $(".office__show").removeClass("show");

        $(this).next().toggleClass("show");
        $(this).toggleClass("show");
    }
});
$(".filters").niceScroll({
    cursorwidth: "5px",
    autohidemode: false,
    horizrailenabled: false,
});
